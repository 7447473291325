/* eslint-disable array-callback-return */
import { Form } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

export const SpecialFiledFieldAction = async ({
  inObject,
  inValue,
  StateVariable,
  corefieldflag,
  status,
}) => {
  const CoreFieldStatusDataFill = ({
    StateVariable,
    inObject,
    inValue,
    status,
  }) => {
    if (status === 'visitor_details') {
      StateVariable((prevState) => ({
        ...prevState,
        visitor_info: {
          ...prevState.visitor_info,
          [inObject.attribute_name]: inValue,
        },
      }))
    } else {
      StateVariable((prevState) => ({
        ...prevState,
        [inObject.attribute_name]: inValue,
      }))
    }
  }
  switch (status) {
    case 'visit_details':
      if (corefieldflag === true) {
        CoreFieldStatusDataFill(StateVariable, inObject, inValue, status)
      } else {
        StateVariable((prevState) => ({
          ...prevState,
          custom_field_details: {
            ...prevState.custom_field_details,
            visit_details: {
              ...prevState.custom_field_details.visit_details,
              [inObject.attribute_name]: inValue,
            },
          },
        }))
      }
      break
    case 'visitor_details':
      if (corefieldflag === true) {
        CoreFieldStatusDataFill(StateVariable, inObject, inValue, status)
      } else {
        StateVariable((prevState) => ({
          ...prevState,
          custom_field_details: {
            ...prevState.custom_field_details,
            visit_details: {
              ...prevState.custom_field_details.visit_details,
              [inObject.attribute_name]: inValue,
            },
          },
        }))
      }
      break
    case 'assign_details':
      if (corefieldflag === true) {
        CoreFieldStatusDataFill(StateVariable, inObject, inValue, status)
      } else {
        StateVariable((prevState) => ({
          ...prevState,
          custom_field_details: {
            ...prevState.custom_field_details,
            assigned_details: {
              ...prevState.custom_field_details.assigned_details,
              [inObject.attribute_name]: inValue,
            },
          },
        }))
      }
      break
    case 'en_route_details':
      if (corefieldflag === true) {
        CoreFieldStatusDataFill(StateVariable, inObject, inValue, status)
      } else {
        StateVariable((prevState) => ({
          ...prevState,
          custom_field_details: {
            ...prevState.custom_field_details,
            en_route_details: {
              ...prevState.custom_field_details.en_route_details,
              [inObject.attribute_name]: inValue,
            },
          },
        }))
      }
      break
    case 'check_in_details':
      if (corefieldflag === true) {
        CoreFieldStatusDataFill(StateVariable, inObject, inValue, status)
      } else {
        StateVariable((prevState) => ({
          ...prevState,
          custom_field_details: {
            ...prevState.custom_field_details,
            check_in_details: {
              ...prevState.custom_field_details.check_in_details,
              [inObject.attribute_name]: inValue,
            },
          },
        }))
      }
      break
    default:
      return ''
  }
}
export const CatchYourDesiredObject = ({
  mainObjectArray,
  searchKey,
  searchValue,
  ResultantSetState,
}) => {
  const filteredDetailsDistrubutor = []
  Object.keys(mainObjectArray).forEach((detailType) => {
    if (Array.isArray(mainObjectArray[detailType])) {
      const filteredTypeDetailsDist = mainObjectArray[detailType].filter(
        (detail) => {
          console.log(detail[searchKey])
          return detail[searchKey] === searchValue
        },
      )

      if (filteredTypeDetailsDist.length > 0) {
        filteredDetailsDistrubutor.push({
          detailType,
          details: filteredTypeDetailsDist,
        })
      }
    }
  })
  return ResultantSetState(filteredDetailsDistrubutor)
}

export function findValueByAttributeName(obj, attributeName) {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      const nestedValue = findValueByAttributeName(obj[key], attributeName)
      if (nestedValue !== undefined) {
        return nestedValue
      }
    } else if (key === attributeName) {
      return obj[key]
    }
  }
}

export function ChildRenderFunction({
  parentCounterDomainId,
  childrenFieldDetails,
  customMetadata,
  visit,
  visitCustomData,
  attributeName,
  handleChangeCheckin,
  domainLookUps,
  entry,
  setattachemntlist,
  attachmentlist,
  field,
}) {
  const ChildDetails = childrenFieldDetails?.find(
    (childObj) => childObj.parent_id === parentCounterDomainId,
  )
  function findValueByPartialKey(obj, targetKey) {
    let foundValue = undefined

    function searchObject(currentObj) {
      for (const key in currentObj) {
        if (typeof currentObj[key] === 'object') {
          searchObject(currentObj[key])
        } else if (key === targetKey) {
          foundValue = currentObj[key]
          return // Exit loop early if key is found
        }
      }
    }
    searchObject(obj)
    return foundValue
  }
  const renderOptions = (domainvalue) => {
    let options = []
    // eslint-disable-next-line array-callback-return
    domainLookUps?.filter((option) => {
      if (option?.domain_type === domainvalue) {
        options?.push({
          label: option?.domain_value,
          value: option?.domain_value,
        })
      }
    })
    return options
  }
  const options = renderOptions(ChildDetails?.child_domain_type)

  return (
    <>
      <div>
        {ChildDetails ? (
          (function () {
            switch (ChildDetails?.child_type) {
              case 'list':
                return (
                  <>
                    <br></br>

                    <Form.Field
                      id={ChildDetails?.child_label}
                      label={ChildDetails?.child_label}
                      options={options}
                      value={
                        customMetadata[entry]?.core_field
                          ? visit[ChildDetails?.child_label]
                          : visitCustomData !== null
                            ? visitCustomData[ChildDetails?.child_label]
                            : null
                      }
                      disabled={
                        ChildDetails?.child_domain_type !== null &&
                        (ChildDetails?.child_domain_type === 'DISTRIBUTOR' ||
                          ChildDetails?.child_domain_type === 'MANUFACTURE')
                          ? true
                          : false
                      }
                      type="select"
                      size="expanded"
                      onUpdate={(id, value) => {
                        handleChangeCheckin(field, ChildDetails[entry], value)
                      }}
                    />

                    <br></br>
                  </>
                )
              case 'text':
                return (
                  <>
                    <Form.Field
                      label={ChildDetails?.child_label}
                      key={ChildDetails?.child_label}
                      // size="expanded"
                      onChange={(event) => {
                        handleChangeCheckin(
                          field,
                          ChildDetails,
                          event.target.value,
                        )
                      }}
                      type="text"
                    />
                    <br></br>
                  </>
                )
              case 'number':
                return (
                  <>
                    <br></br>

                    <Form.Field
                      label={ChildDetails?.child_label}
                      // size="expanded"
                      onChange={(event) => {
                        handleChangeCheckin(
                          field,
                          customMetadata[entry],
                          event.target.value,
                        )
                      }}
                      type="number"
                    />
                  </>
                )
              case 'Camera':
                return (
                  <>
                    <br></br>
                    <br></br>
                  </>
                )
              case 'date':
                return (
                  <>
                    <br></br>

                    <br></br>
                  </>
                )
              case 'boolean':
                return (
                  <>
                    <br></br>

                    <br></br>
                  </>
                )
              case 'multiselect':
                return (
                  <>
                    <br></br>

                    <Autocomplete
                      multiselect
                      id={ChildDetails?.child_label}
                      label={ChildDetails?.child_label}
                      value={
                        ChildDetails?.attribute_name
                          ? findValueByPartialKey(
                              visit,
                              ChildDetails?.attribute_name,
                            )
                          : ''
                      }
                      options={options}
                      onUpdate={(id, value) => {
                        console.log(value)
                        handleChangeCheckin(field, ChildDetails, value)
                      }}
                    />

                    <br></br>
                  </>
                )
              default:
                return ''
            }
          })()
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export const validateDetails = (details, visit, customFieldDetails) => {
  let message = null

  details?.map((mappedObject) => {
    if (mappedObject?.visible === true && mappedObject?.required === true) {
      const attributeValue = mappedObject?.core_field
        ? visit[mappedObject.attribute_name]
        : visit.custom_field_details[customFieldDetails][
            mappedObject?.attribute_name
          ]

      if (
        attributeValue === '' ||
        attributeValue === undefined ||
        attributeValue === null
      ) {
        message = mappedObject?.attribute_name + ' Cannot Be Blank'
      }
    }
  })

  return message
}
