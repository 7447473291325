/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import {
  GetAdminMetadata,
  GetByVisitId,
  GetDomainList,
  GetVisitorTypeById,
  ValidateUUID,
  ValidateUUIDForReview,
  getDownloadAttachmentsByCaseId,
  getDownloadCaseAttachmentByTossId,
} from '../service/VisitExternalApiCalls'
import { useNavigate } from 'react-router-dom'
import { ResponseContext } from './ResponseContext'
import { ScreenAccessContext } from './ScreenAccessContext'
import { LoadingContext } from './LoadingContext'

const VisitUUIDContext = React.createContext()

const VisitUUIDContextProvider = ({ children }) => {
  const [visitUUID, setVisitUUID] = React.useState(null)
  const [locationId, setLocationId] = React.useState(null)
  const [visitorTypeId, setVisitorTypeId] = React.useState(null)
  const [systemName, setSystemName] = React.useState(null)
  const [visitorTypeDetails, setVisitorTypeDetails] = React.useState(null)
  const [domainLookUp, setDomainLookUp] = React.useState(null)
  const [customMetaData, setCustomMetaData] = React.useState(null)
  const [visitID, setVisitID] = React.useState(null)
  const [visitDetails, setVisitDetails] = React.useState(null)
  const { response } = React.useContext(ResponseContext)
  const { screenAccess } = React.useContext(ScreenAccessContext)
  // eslint-disable-next-line no-unused-vars
  const { Loading, setLoading } = React.useContext(LoadingContext)
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()
  const [holdStatus, setHoldStatus] = React.useState(null)
  const [reviewResponse, setReviewResponse] = React.useState({})
  const [tossAttachmentResponse, setTossAttachmentResponse] = React.useState({})

  const [caseAttachements, setCaseAttachments] = React.useState([])
  React.useEffect(() => {
    const url = window?.location?.href
    const parts = url.split('/')
    let uuid
    let attachmentUUID = null
    let status
    if (parts.includes('check_in_hold')) {
      setLoading(true)
      // If the URL contains 'check_in_hold', extract UUID and status
      uuid = parts[parts.indexOf('check_in_hold') - 1]
      status = 'check_in_hold'
    } else if (parts.includes('dsd_cases')) {
      setLoading(true)
      uuid = parts[parts.length - 1]
    } else if (parts.includes('case_attachment')) {
      setLoading(true)
      attachmentUUID = parts[parts.length - 1]
    } else {
      setLoading(true)
      // If 'check_in_hold' is not present, simply extract the UUID
      uuid = parts[parts.length - 1]
    }
    if (attachmentUUID == null) {
      if (uuid) {
        setLoading(true)
        if (parts.includes('dsd_cases')) {
          ValidateUUIDForReview(uuid)
            .then((res) => {
              setReviewResponse(res)
              getDownloadAttachmentsByCaseId(res.case_id, uuid).then(
                (response) => {
                  setCaseAttachments(response)
                },
              )
            })
            .catch((err) => {
              setVisitUUID(null)
              setLoading(false)
              setOpen(true)
            })
        } else {
          ValidateUUID(uuid)
            .then((res) => {
              setVisitUUID(res?.uuid)
              setLocationId(res.location_id)
              setVisitorTypeId(res?.visitor_type_id)
              setVisitID(res?.visit_id)
              setLoading(false)
            })
            .catch((err) => {
              setVisitUUID(null)
              setLoading(false)
              setOpen(true)
            })
        }
      } else {
        setLoading(false)
        setVisitUUID(null)
      }
    } else {
      getDownloadCaseAttachmentByTossId(attachmentUUID)
        .then((res) => {
          setTossAttachmentResponse(res)
        })
        .catch((err) => {
          setVisitUUID(null)
          setLoading(false)
          setOpen(true)
        })
    }

    // Optionally, you can set the status here if needed
    if (status) {
      setLoading(true)
      setHoldStatus(status)
    }
    setLoading(false)
  }, [open])
  React.useEffect(() => {
    if (visitorTypeId != null && visitUUID != null) {
      setLoading(true)
      GetVisitorTypeById(visitorTypeId, visitUUID).then((res) => {
        setSystemName(res?.system_name)
        setVisitorTypeDetails(res)
        setLoading(false)
      })
      setLoading(false)
    }

    if (visitorTypeId != null && visitUUID != null) {
      setLoading(true)
      GetAdminMetadata(visitorTypeId, locationId, visitUUID).then((res) => {
        setCustomMetaData(res[0])
        setLoading(false)
      })
      setLoading(false)
    }
  }, [locationId, visitUUID, visitorTypeId])

  React.useEffect(() => {
    if (visitorTypeId != null && visitUUID != null && visitID != null) {
      setLoading(true)
      GetVisitorTypeById(visitorTypeId, visitUUID).then((res) => {
        setSystemName(res?.system_name)
        setVisitorTypeDetails(res)
        setLoading(false)
      })
      setLoading(false)
    }
    visitorTypeId != null &&
      visitUUID != null &&
      visitID != null &&
      GetAdminMetadata(visitorTypeId, locationId, visitUUID).then((res) => {
        setCustomMetaData(res[0])
      })

    visitorTypeId != null &&
      visitUUID != null &&
      visitID != null &&
      GetByVisitId(visitID, visitUUID).then((res) => {
        setVisitDetails(res)
      })
  }, [locationId, visitID, visitUUID, visitorTypeId])

  React.useEffect(() => {
    systemName != null &&
      locationId != null &&
      GetDomainList(systemName, locationId, visitUUID).then((res) => {
        setDomainLookUp(res)
      })
  }, [locationId, systemName, visitUUID])

  React.useEffect(() => {
    if (
      visitUUID != null &&
      visitorTypeId != null &&
      systemName != null &&
      locationId != null &&
      domainLookUp != null &&
      customMetaData != null &&
      visitDetails != null &&
      holdStatus
    ) {
      setLoading(true)
      if (
        visitID != null &&
        holdStatus === 'check_in_hold' &&
        (visitDetails?.visit_status === 'Assigned' ||
          visitDetails?.visit_status === 'en-route')
      ) {
        setLoading(false)
        navigate(`/checkinhold/${visitUUID}`)
      }
      if (visitDetails?.visit_status === 'Check-out') {
        setVisitUUID(null)
        setLoading(false)
        navigate('/')
      }
      setLoading(false)
    }
    if (
      visitUUID != null &&
      visitorTypeId != null &&
      systemName != null &&
      locationId != null &&
      domainLookUp != null &&
      customMetaData != null &&
      visitDetails == null
    ) {
      setLoading(true)
      if (visitID === null && response.routeFlag === '') {
        setLoading(false)
      }
      if (visitID === null && response?.routeFlag === 'created') {
        setLoading(false)
        navigate(`/visitsuccess/${visitUUID}`)
      }
      if (visitID === null && response.routeFlag === 'netural') {
        setLoading(false)
        navigate('/')
      }
      setLoading(false)
    }

    if (
      visitUUID != null &&
      visitorTypeId != null &&
      systemName != null &&
      locationId != null &&
      domainLookUp != null &&
      customMetaData != null &&
      visitDetails != null
    ) {
      setLoading(true)
      if (
        visitDetails != null &&
        visitUUID != null &&
        visitID != null &&
        holdStatus === null
      ) {
        if (
          (visitDetails?.visit_status === 'Assigned' ||
            visitDetails?.visit_status === 'en-route') &&
          response.routeFlag === ''
        ) {
          setLoading(false)
          navigate(`/checkin/${visitUUID}`)
        }

        if (
          visitDetails?.visit_status === 'Check-in' &&
          response.routeFlag === ''
        ) {
          setLoading(false)
          navigate(`/checkout/${visitUUID}`)
        }

        if (response?.routeFlag === 'checkincreated') {
          setLoading(false)
          navigate(`/checkinoutsuccess/${visitUUID}`)
        }
        if (response.routeFlag === 'checkoutcreated') {
          setLoading(false)
          navigate(`/checkinoutsuccess/${visitUUID}`)
        }
        if (response.routeFlag === 'netural') {
          setLoading(false)
          navigate('/')
        }
        if (visitDetails?.visit_status === 'Check-out') {
          setLoading(false)
          setVisitUUID(null)
        }
        setLoading(false)
      }
      setLoading(false)
    }
  }, [
    customMetaData,
    domainLookUp,
    holdStatus,
    locationId,
    navigate,
    open,
    response.routeFlag,
    screenAccess.createvisit,
    systemName,
    visitDetails,
    visitID,
    visitUUID,
    visitorTypeId,
    tossAttachmentResponse,
  ])

  return (
    <>
      <VisitUUIDContext.Provider
        value={{
          setVisitUUID,
          visitUUID,
          visitorTypeId,
          locationId,
          domainLookUp,
          visitorTypeDetails,
          customMetaData,
          visitID,
          open,
          setOpen,
          reviewResponse,
          caseAttachements,
          setCaseAttachments,
          tossAttachmentResponse,
        }}
      >
        {children}
      </VisitUUIDContext.Provider>
    </>
  )
}
export { VisitUUIDContext, VisitUUIDContextProvider }
