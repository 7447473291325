import { Button, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { ErrorIcon } from '@enterprise-ui/icons'
import { useNavigate } from 'react-router-dom'
export const FailedValidationScreen = ({ open, setOpen }) => {
  const navigate = useNavigate()
  const closeModal = () => {
    setOpen(false)
    navigate('/')
  }
  console.log('call')
  return (
    <Modal isVisible={open} onRefuse={closeModal} size="expanded">
      <Grid.Container className="hc-ta-center" direction="column">
        <Grid.Item className="hc-clr-alert hc-ta-center hc-fs-xl">
          <EnterpriseIcon icon={ErrorIcon} /> Invalid Link!
        </Grid.Item>
        <Grid.Item className="hc-pl-expanded hc-fs-lg">
          Please enter a valid link and try again.
        </Grid.Item>
        <br></br>
        <Grid.Item className="hc-pl-expanded hc-fs-lg"></Grid.Item>
        <Grid.Item className="hc-pl-expanded hc-fs-md">
          You can also consult our target for more assistance.
        </Grid.Item>
        <Grid.Item style={{ margin: 'auto', marginBottom: '10px' }}>
          <Button
            type="primary"
            className="hc-pa-expanded"
            onClick={closeModal}
          >
            Okay
          </Button>
        </Grid.Item>
      </Grid.Container>
    </Modal>
  )
}
