import { SubMain } from './SubMain'
import { VisitUUIDContextProvider } from '../contexts/VisitUUIDContext'
import { ResponseContextProvider } from '../contexts/ResponseContext'
import { ScreenAccessContextProvider } from '../contexts/ScreenAccessContext'
import { LoadingContextProvider } from '../contexts/LoadingContext'
export const Main = () => {
  return (
    <>
      <ResponseContextProvider>
        <LoadingContextProvider>
          <ScreenAccessContextProvider>
            <VisitUUIDContextProvider>
              <SubMain />
            </VisitUUIDContextProvider>
          </ScreenAccessContextProvider>
        </LoadingContextProvider>
      </ResponseContextProvider>
    </>
  )
}
