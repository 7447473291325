import * as React from 'react'
const ScreenAccessContext = React.createContext()

const ScreenAccessContextProvider = ({ children }) => {
  const [screenAccess, setScreenAccess] = React.useState({
    createvisit: '',
    checkin: '',
    checkout: '',
  })
  return (
    <>
      <ScreenAccessContext.Provider
        value={{
          setScreenAccess,
          screenAccess,
        }}
      >
        {children}
      </ScreenAccessContext.Provider>
    </>
  )
}
export { ScreenAccessContext, ScreenAccessContextProvider }
