import * as React from 'react'
import { Card, Grid, Heading, Layout } from '@enterprise-ui/canvas-ui-react'
import { VisitUUIDContext } from '../../contexts/VisitUUIDContext'
import { useEnv } from '@praxis/component-runtime-env'
import Base64AttachImage from './ConvertBase64toImage'

export const AttachmentView = () => {
  const { tossAttachmentResponse } = React.useContext(VisitUUIDContext)
  const cardStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%', // Ensure the card takes up the available height
    width: '100%', // Ensure the card takes up the available width
    padding: '20px', // Add padding around the iframe
    border: '2px solid #000', // Set the border for the card
    borderRadius: '10px', // Optional: add rounded corners to the card
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: add a subtle shadow for a better visual effect
    boxSizing: 'border-box', // Include padding and border in the element's total width and height
  }
  const env = useEnv()

  return (
    <>
      <Layout.Body includeRail>
        <Grid.Container
          direction="row"
          justify="center"
          style={{ paddingBottom: '20px' }}
          xs={12}
        >
          {tossAttachmentResponse.base64_data != null ? (
            <>
              <Card style={cardStyles}>
                <Base64AttachImage
                  base64String={tossAttachmentResponse?.base64_data}
                />
              </Card>
            </>
          ) : (
            <>
              <Card>
                <div className="hc-pa-normal hc-ta-center">
                  <Heading heading={5}>Welcome to {env.HomeText}</Heading>
                  <p className="hc-pa-normal hc-fs-md">
                    You navigated to an unused page in {env.HomeText}. Please
                    use your company link for valid attachment link
                  </p>
                </div>
              </Card>
            </>
          )}
        </Grid.Container>
      </Layout.Body>
    </>
  )
}
