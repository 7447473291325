import React from 'react'
import { Button, Grid, Input, Layout } from '@enterprise-ui/canvas-ui-react'
import PlaywoodIcon from '../../assets/Icons/playwood.png'
import { useNavigate } from 'react-router-dom'
import { ScreenAccessContext } from '../../contexts/ScreenAccessContext'
// import moment from 'moment-timezone'
import { useEnv } from '@praxis/component-runtime-env'
import { ResponseContext } from '../../contexts/ResponseContext'
// import { VisitUUIDContext } from '../../contexts/VisitUUIDContext'

export const SuccessScreenNewVisit = () => {
  const { setResponse } = React.useContext(ResponseContext)
  const { setScreenAccess } = React.useContext(ScreenAccessContext)

  // const { locationId } = React.useContext(VisitUUIDContext)
  const env = useEnv()
  const navigate = useNavigate()

  //   function convertUtcTimeToStoreTime(time) {
  //     const utcTime = moment.utc(time, 'YYYYMMDD HH:mm:ss')
  //     const storeTime =
  //       utcTime &&
  //       utcTime
  //         .clone()
  //         .tz(optedLocationDetail === undefined ? 'America/Chicago' : locationId)
  //         .format('YYYY-MM-DD h:mm:ss A')
  //     return storeTime
  //   }

  // React.useEffect(() => {
  //   setTimeout(() => navigate('/'), 1500)
  // }, [navigate])

  return (
    <>
      <Layout.Body includeRail>
        <Grid.Container direction="column" justify="center" align="center">
          <Grid.Item xs={12} sm={6} md={4}>
            <img src={PlaywoodIcon} alt="playwoodicon" />
          </Grid.Item>
          <Grid.Item xs={12} sm={8} md={8} align="center">
            <Input.Info className="hc-fs-lg hc-clr-success">
              {' '}
              Your details have been successfully submitted!
            </Input.Info>
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
      <Layout.Footer includeRail className="hc-pv-4x">
        <Grid.Container direction="column" justify="center" align="center">
          <Grid.Item className="hc-pa-xl">
            <Input.Info className="hc-fs-xl hc-ta-center">
              You can either instantly check-in or send link via email to
              check-in later
            </Input.Info>
          </Grid.Item>
          <Grid.Item>
            <Grid.Container
              direction="row"
              justify="center"
              className="hc-pa-xl"
            >
              <Grid.Item>
                {env?.sendViaEmailButton === true ? (
                  <>
                    <Button
                      type="secondary"
                      size="expanded"
                      onClick={() => {}}
                      className="hc-pa-expanded"
                    >
                      Send link via email
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </Grid.Item>
              <Grid.Item>
                {env?.MarkInstantCheckInButton === true ? (
                  <>
                    <Button
                      type="primary"
                      size="expanded"
                      onClick={() => {}}
                      className="hc-pa-expanded"
                    >
                      Mark Instant Check-in
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
        <Grid.Container
          direction="row"
          justify="flex-end"
          className="hc-pa-expanded"
        >
          <Grid.Item>
            <Button
              type="primary"
              className="hc-pa-expanded"
              size="expanded"
              onClick={() => {
                setScreenAccess({
                  createvisit: false,
                  checkin: false,
                  checkout: false,
                })
                setResponse({
                  routeFlag: 'netural',
                })
                navigate('/')
              }}
            >
              Okay
            </Button>
          </Grid.Item>
        </Grid.Container>
      </Layout.Footer>
    </>
  )
}
