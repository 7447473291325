import { useState, useContext } from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import { MainRouter } from '../globalComponents/baseComponents/MainRouter'
import { Header } from '../globalComponents/baseComponents/Header'
// import { Footer } from '../globalComponents/baseComponents/Footer'
import { LoadingComponent } from '../globalComponents/baseComponents/LoadingComponent'
import { FailedValidationScreen } from './additionalscreen/FailedValidationScreen'
import { VisitUUIDContext } from '../contexts/VisitUUIDContext'
// import { FooterHandlerContext } from '../contexts/FooterHandlerContext'
export const SubMain = () => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(true)
  const { open, setOpen } = useContext(VisitUUIDContext)
  const handleSideNavToggle = () => {
    setIsSideNavOpen(!isSideNavOpen)
  }

  return (
    <>
      <Layout
        theme="default" // configure theme here
        data-testid="mainAuthenticatedLayout"
        darkMode="false"
        fullWidth
      >
        <Header onSideNavToggle={handleSideNavToggle} />
        <MainRouter />
        <LoadingComponent />
        {open && <FailedValidationScreen open={open} setOpen={setOpen} />}
      </Layout>
    </>
  )
}
