import axios from 'axios'
import appConfig from '../globalConfig/appConfig'

export const GetAdminMetadata = async (visitorTypeId, locationId, uuid) => {
  try {
    const config = await appConfig()
    const headers = {
      External_UUID: uuid,
    }
    const response = await axios.get(
      `${config.baseUrl}visit_metadata?visitor_type_id=${visitorTypeId}&location_id=${locationId}`,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    console.error('Error fetching Api:', error)
    throw error
  }
}

export const GetDomainList = async (systemName, locationId, uuid) => {
  try {
    const config = await appConfig()
    const headers = {
      External_UUID: uuid,
    }
    const response = await axios.get(
      `${config.baseUrl}domains/all?system_name=${systemName}&location_id=${locationId}`,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    console.error('Error fetching Api:', error)
    throw error
  }
}

export const CreateVisitService = async (params, uuid) => {
  try {
    const config = await appConfig()
    return axios.post(`${config.baseUrl}visit`, params, {
      headers: {
        'Content-Type': 'application/json',
        External_UUID: uuid,
      },
    })
  } catch (error) {
    console.error('Error creating visit:', error)
    throw error
  }
}

export const UpdateVisit = async (visitId, params) => {
  const config = await appConfig()
  return axios.put(
    `${config.baseUrl}/visits/v2/${visitId}?key=${config.apiKey}`,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const AcceptReview = async (caseId, uuid, params) => {
  try {
    const config = await appConfig()
    return axios.put(
      `${config.chatBotUrl}case_id/${caseId}?key=${config.apiKey}`,
      params,
      {
        headers: {
          'Content-Type': 'application/json',
          External_UUID: uuid,
        },
      },
    )
  } catch (error) {
    console.error('Error creating visit:', error)
    throw error
  }
}

export const UpdateCase = async (caseId, uuid, params) => {
  const config = await appConfig()
  return axios.put(
    `${config.chatBotUrl}case_id/${caseId}?key=${config.apiKey}`,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
        External_UUID: uuid,
      },
    },
  )
}

export const CreateDocumentUpload = async (params) => {
  const config = await appConfig()
  return axios.post(
    `${config.chatBotUrl}/case_id/docs?key=${config.apiKey}`,
    params,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const GetByVisitId = async (visitId, uuid) => {
  try {
    const config = await appConfig()
    const headers = {
      External_UUID: uuid,
    }
    const response = await axios.get(`${config.baseUrl}visit/${visitId}`, {
      headers,
    })

    return response.data
  } catch (error) {
    console.error('Error fetching Api:', error)
    throw error
  }
}

export const ValidateUUID = async (uuid) => {
  try {
    const config = await appConfig()
    const headers = {
      External_UUID: uuid,
    }
    const response = await axios.get(`${config.baseUrl}validate/${uuid}`, {
      headers,
    })

    return response.data
  } catch (error) {
    console.error('Error fetching Api:', error)
    throw error
  }
}

export const ValidateUUIDForReview = async (uuid) => {
  try {
    const config = await appConfig()
    const headers = {
      External_UUID: uuid,
    }
    const response = await axios.get(
      `${config.chatBotUrl}validate_cases/${uuid}`,
      {
        headers,
      },
    )
    return response.data
  } catch (error) {
    console.error('Error fetching Api:', error)
    throw error
  }
}

export const GetVisitorTypeById = async (visitorTypeId, uuid) => {
  try {
    const config = await appConfig()
    const headers = {
      External_UUID: uuid,
    }
    const response = await axios.get(
      `${config.baseUrl}visitor/types/${visitorTypeId}`,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    console.error('Error fetching Api:', error)
    throw error
  }
}

export const getDownloadAttachmentsByCaseId = async (case_id, uuid) => {
  try {
    const config = await appConfig()
    const headers = {
      External_UUID: uuid,
    }
    const response = await axios.get(
      `${config.chatBotUrl}download_attachments/${case_id}`,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    console.error('Error fetching Api:', error)
    throw error
  }
}

export const getDownloadCaseAttachmentByTossId = async (attachmentid) => {
  try {
    const config = await appConfig()
    const headers = {
      External_UUID: attachmentid,
    }
    const response = await axios.get(
      `${config.chatBotUrlAttachment}download_attachments_id/${attachmentid}`,
      {
        headers,
      },
    )

    return response.data
  } catch (error) {
    console.error('Error fetching Api:', error)
    throw error
  }
}
