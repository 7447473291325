import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Grid, Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { TrashIcon } from '@enterprise-ui/icons'
import {
  UpdateCase,
  getDownloadAttachmentsByCaseId,
} from '../../service/VisitExternalApiCalls'
import { VisitUUIDContext } from '../../contexts/VisitUUIDContext'

const getStyles = () => ({
  selectedFileTxtCls: {
    textAlign: 'left',
    marginTop: 10,
    fontSize: '1rem',
  },
  fileNameCls: {
    fontSize: '1rem',
    textAlign: 'left',
  },
  attachedFileCls: {
    backgroundColor: '#eaeaea',
    padding: '0 10px',
    margin: 'auto',
    width: 'inherit',
  },
  fileRemoveCls: {
    textAlign: 'right',
  },
  iframeStyles: {
    marginTop: 15,
    marginLeft: 5,
    width: '100%',
    height: '150px',
    border: 'none',
  },
})

export const FileUploadComponent = ({ data, attchedResponse }) => {
  const [file, setFile] = useState(null)
  const [fileObjects, setFileObjects] = React.useState([])
  const [pdfUrl, setPdfUrl] = useState(null)
  const [base64String, setBase64String] = React.useState(null)
  const { setCaseAttachments } = React.useContext(VisitUUIDContext)
  const styles = getStyles()

  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0])
    const pdfFile = acceptedFiles[0]
    const pdfObjectURL = URL.createObjectURL(pdfFile)
    setPdfUrl(pdfObjectURL)
    setFileObjects([...fileObjects, pdfFile])
    convertFileToBase64(pdfFile)
  }

  const convertFileToBase64 = (file) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      setBase64String(event.target.result)
    }
    reader.readAsDataURL(file)
  }
  const removeFile = (fileIndex) => {
    const updatedFiles = [...fileObjects]
    updatedFiles.splice(fileIndex, 1)
    setFileObjects(updatedFiles)
    setFile(null)
    setPdfUrl(null)
    setBase64String(null)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpg',
    multiple: false,
    maxFiles: 1,
  })

  const handleUpload = () => {
    UpdateCase(data['case_id'], data['uuid'], {
      user_id: data.user_id,
      updated_by: data.user_id,
      attachments: [{ name: file.name, base64_data: base64String }],
    }).then((response) => {
      removeFile(0)
      getDownloadAttachmentsByCaseId(data['case_id'], data['uuid']).then(
        (resp) => {
          setCaseAttachments(resp)
        },
      )
    })
  }

  return (
    <>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #cccccc',
          padding: '20px',
          cursor: 'pointer',
        }}
      >
        <Button
          type="primary"
          size="expanded"
          className="hc-pa-expanded hc-ma-md"
        >
          Add Attachment
        </Button>
        <input {...getInputProps()} />
        <p>Drag 'n' drop a JPG file here, or click to select one</p>
      </div>
      <Grid.Container
        direction="row"
        justify="flex-start"
        style={{ paddingBottom: '20px' }}
        xs={12}
      >
        {pdfUrl && (
          <iframe
            src={pdfUrl}
            title="Image Preview"
            style={styles.iframeStyles}
          />
        )}
      </Grid.Container>
      {file && (
        <>
          <p style={styles.selectedFileTxtCls}>
            <b>Selected file:</b>
          </p>
          <Grid.Container
            direction="row"
            justify="flex-start"
            align="center"
            style={styles.attachedFileCls}
          >
            <Grid.Item xs={10}>
              <p className="hc-fs-lg">
                <p style={styles.fileNameCls}>{file.name}</p>
              </p>
            </Grid.Item>
            <Grid.Item xs={2}>
              <p className="hc-fs-lg" style={styles.fileRemoveCls}>
                {' '}
                <EnterpriseIcon
                  icon={TrashIcon}
                  size="md"
                  onClick={() => removeFile(0)}
                />
              </p>
            </Grid.Item>
          </Grid.Container>
        </>
      )}

      {file && (
        <button
          onClick={handleUpload}
          style={{ marginTop: '20px', padding: '10px 20px' }}
        >
          Upload
        </button>
      )}
    </>
  )
}
