import { BrowserRouter as Router } from 'react-router-dom'
import '@enterprise-ui/canvas-ui-css'
import { HelveticaForTarget } from '@enterprise-ui/component-font'
import './globalStyles/customStyles.scss'
import { Main } from './views/Main'
import '../src/globalStyles/Main-2.css'
import '../src/globalStyles/Maincss-1.css'
import '../src/globalStyles/Maincss-2.css'
import '../src/globalStyles/Maincss-3.css'
import '../src/globalStyles/Maincss-4.css'
import '../src/globalStyles/Maincss-5.css'
import { EnvProvider } from '@praxis/component-runtime-env'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'

const App = () => {
  return (
    <>
      <EnvProvider configPath={'/config.json'}>
        <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
        <ToastProvider location="top">
          <Router>
            <Main />
          </Router>
        </ToastProvider>
      </EnvProvider>
    </>
  )
}

export default App
