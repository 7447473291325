import * as React from 'react'
import {
  Layout,
  Grid,
  Button,
  ToastProvider,
  Input,
  Divider,
} from '@enterprise-ui/canvas-ui-react'
import { UpdateVisit } from '../../../service/VisitExternalApiCalls'
import RenderFields from '../../Utils/RenderFields'
import { VisitUUIDContext } from '../../../contexts/VisitUUIDContext'
import moment from 'moment-timezone'
import EnterpriseIcon, { ChevronRightIcon } from '@enterprise-ui/icons'

export const CheckInHoldIndex = () => {
  const {
    systemName,
    customMetaData,
    visitorTypeDetails,
    locationId,
    visitUUID,
    domainLookUp,
    visitID,
    visitorTypeId,
  } = React.useContext(VisitUUIDContext)

  const [autocompleteValue, setAutocompleteValue] = React.useState(null)
  const [signedDocument, setSignedDocument] = React.useState([])
  const makeToast = ToastProvider.useToaster()
  //   const navigate = useNavigate()
  const [visitCheckin, setVisitCheckin] = React.useState({
    visitor_type_id: '',
    reference_id: '',
    visit_status: 'Check-in_hold',
    location_id: '',
    visit_purpose: '',
    estimated_time_arrival: null,
    estimated_time_complete: null,
    sender_source: 'DC',
    visitor_id: null,
    is_tac_enabled: false,
    is_sms_enabled: false,
    is_email_enabled: false,
    visitor_info: {
      visitor_id: null,
      title_prefix: '',
      first_name: '',
      last_name: '',
      company_id: '',
      employee_id: '',
      company_name: '',
      email_address: '',
      phone_number: '',
      type: '',
      created_by: '',
      updated_by: '',
      created_time: '',
      modified_time: '',
      visitor_details: {},
    },
    updated_by: `${systemName}-external`,
    user_id: `${systemName}-external`,
    attachments: [],
    custom_field_details: {
      visit_details: {},
      check_in_details: {},
      check_in_hold_details: {},
      check_out_details: {},
    },
  })

  const handleChangeCheckInn = (key, obj, value) => {
    if (obj.core_field === true) {
      if ([obj?.attribute_name] in visitCheckin.visitor_info) {
        setVisitCheckin((prevState) => ({
          ...prevState,
          visitor_info: {
            ...prevState.visitor_info,
            [obj.attribute_name]: value,
          },
        }))
      } else {
        setVisitCheckin((prevState) => ({
          ...prevState,
          [obj.attribute_name]: value,
        }))
      }
    } else {
      setVisitCheckin((prevState) => ({
        ...prevState,
        custom_field_details: {
          ...prevState.custom_field_details,
          check_in_details: {
            ...prevState.custom_field_details?.check_in_details,
            [obj?.attribute_name]: value,
          },
        },
      }))
    }
  }
  const getCheckInformDetails = () => {
    return (
      <>
        <RenderFields
          field={'check_in_details'}
          customData={customMetaData?.custom_field_details?.check_in_details}
          domainLookUp={domainLookUp}
          handleChangeOption={handleChangeCheckInn}
          visit={visitCheckin}
          setAutocompleteValue={setAutocompleteValue}
          autocompleteValue={autocompleteValue}
          setSignedDocument={setSignedDocument}
          signedDocument={signedDocument}
        />
      </>
    )
  }
  const validationForCheckIn = () => {
    let message = null
    Object.keys(customMetaData?.custom_field_details.check_in_details).map(
      // eslint-disable-next-line
      (entry) => {
        const attributeName =
          customMetaData?.custom_field_details.check_in_details[entry]
            .attribute_name

        if (
          customMetaData?.custom_field_details?.check_in_details[entry]
            .visible === true &&
          customMetaData?.custom_field_details?.check_in_details[entry]
            .required === true
        ) {
          if (
            customMetaData?.custom_field_details.check_in_details[entry]
              .core_field === true
          ) {
            if (visitCheckin[attributeName] === '') {
              message =
                message +
                customMetaData?.custom_field_details.check_in_details[entry]
                  .label +
                ' cannot be blank!  '
            }
            if (visitCheckin.visitor_info[attributeName] === '') {
              message =
                message +
                customMetaData?.custom_field_details.check_in_details[entry]
                  .label +
                ' cannot be blank!  '
            }
          }
          if (
            visitCheckin.custom_field_details.check_in_details[
              attributeName
            ] === ''
          ) {
            message =
              message +
              customMetaData?.custom_field_details.check_in_details[entry]
                .label +
              ' cannot be blank!   '
          }
        }
      },
    )

    return message
  }

  const handleError = (event) => {
    var ErrorValidationFunction1 = null
    ErrorValidationFunction1 = validationForCheckIn()
    if (
      ErrorValidationFunction1?.length > 0 &&
      ErrorValidationFunction1 !== undefined &&
      ErrorValidationFunction1 !== null
    ) {
      handleFailureToast(ErrorValidationFunction1)
      console.log(ErrorValidationFunction1)
    }

    if (ErrorValidationFunction1 == null) {
      // setCircleProgress(true)
      handleSubmit(event)
    }
  }

  const handleSuccesToast = (errortext) => {
    makeToast({
      type: 'success',
      heading: 'Success',
      message: errortext,
    })
  }
  const handleFailureToast = (errortext) => {
    makeToast({
      type: 'error',
      heading: 'Error',
      message: errortext,
    })
  }

  const handleSubmit = () => {
    visitCheckin.system_name = systemName
    visitCheckin.location_id = locationId
    visitCheckin.attachments = signedDocument
    visitCheckin.user_id = `${systemName}-external`
    visitCheckin.visitor_type_id = visitorTypeId
    visitCheckin.visit_status = 'Check-in_hold'
    visitCheckin.estimated_time_arrival = visitCheckin?.estimated_time_arrival
      ? moment
          .utc(visitCheckin?.estimated_time_arrival, 'YYYYMMDD HH:mm:ss')
          .toISOString()
      : moment(moment().utcOffset(0).toISOString())
    UpdateVisit(visitID, visitCheckin, visitUUID).then((res) => {
      //   setResponse({
      //     flag: 'checkin',
      //     data: res?.data?.created_time,
      //   })
      handleSuccesToast('')
      //   navigate('/checkinoutsuccess')
    })
  }
  console.log('inside checkinhold')
  return (
    <>
      <Layout.Body includeRail>
        <Grid.Container
          direction="row"
          justify="flex-start"
          style={{ paddingBottom: '20px' }}
          xs={12}
        >
          <Grid.Item xs={12}>
            <Input.Info className="hc-fs-xl">Target Check-in Form</Input.Info>
            <Input.Info className="hc-fs-md">
              Submit your details now for instant check-in at the Target
              facility{' '}
            </Input.Info>
            <Divider width="2" direction="horizontal" wide={5} />
            <Grid.Container direction="row" justify="space-between">
              <Grid.Item>
                <Input.Info className="hc-bg-grey04 hc-pa-lg">
                  <Grid.Container
                    direction="row"
                    justify="center"
                    align="flex-start"
                  >
                    <Grid.Item className="hc-pt-dense">
                      Visitor Type: <strong>{visitorTypeDetails?.type}</strong>
                    </Grid.Item>
                  </Grid.Container>
                </Input.Info>
              </Grid.Item>
              <Grid.Item>
                <Input.Info className="hc-bg-grey04 hc-pa-lg">
                  <Grid.Container
                    direction="row"
                    justify="center"
                    align="flex-start"
                  >
                    <Grid.Item className="hc-pt-dense">
                      Location Id: <strong>{locationId}</strong>
                    </Grid.Item>
                  </Grid.Container>
                </Input.Info>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>

        <Grid.Container
          justify="space-around"
          direction="column"
          spacing="dense"
          //align="center"
          xs={12}
        >
          <Grid.Item>
            <Input
              noValidate
              onSubmit={() => {
                console.log('Input submitted')
              }}
            >
              {customMetaData && getCheckInformDetails()}
            </Input>
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
      <Layout.Footer includeRail>
        <Grid.Container justify="flex-end">
          <Grid.Item>
            {customMetaData ? (
              <>
                <Button
                  type="primary"
                  size="expanded"
                  className="hc-pa-expanded"
                  onClick={() => handleError()}
                >
                  Submit Details <EnterpriseIcon icon={ChevronRightIcon} />
                </Button>
              </>
            ) : (
              <></>
            )}
          </Grid.Item>
        </Grid.Container>
      </Layout.Footer>
    </>
  )
}
