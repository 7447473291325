import * as React from 'react'

const LoadingContext = React.createContext()

const LoadingContextProvider = ({ children }) => {
  const [Loading, setLoading] = React.useState(false)
  return (
    <>
      <LoadingContext.Provider
        value={{
          setLoading,
          Loading,
        }}
      >
        {children}
      </LoadingContext.Provider>
    </>
  )
}
export { LoadingContext, LoadingContextProvider }
