/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import moment from 'moment-timezone'
import { VisitUUIDContext } from '../../contexts/VisitUUIDContext'
import RenderFields from '../Utils/RenderFields'
import { useEnv } from '@praxis/component-runtime-env'

import {
  Button,
  Divider,
  Grid,
  Input,
  Layout,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { CreateVisitService } from '../../service/VisitExternalApiCalls'
import EnterpriseIcon, { ChevronRightIcon } from '@enterprise-ui/icons'
import { ResponseContext } from '../../contexts/ResponseContext'
export const NewVisitIndex = () => {
  const {
    systemName,
    visitorTypeId,
    customMetaData,
    visitorTypeDetails,
    locationId,
    visitUUID,
    domainLookUp,
  } = React.useContext(VisitUUIDContext)
  const env = useEnv()
  const { setResponse } = React.useContext(ResponseContext)
  const makeToast = ToastProvider.useToaster()
  const [signedDocument, setSignedDocument] = React.useState([])
  const [autocompleteValue, setAutocompleteValue] = React.useState(null)
  let initalState = {
    system_name: '',
    reference_id: '',
    visit_status: '',
    location_id: '',
    visit_purpose: '',
    estimated_time_arrival: moment().utc().format(),
    estimated_time_complete: null,
    sender_source: systemName,
    visitor_id: null,
    is_tac_enabled: false,
    is_sms_enabled: false,
    is_email_enabled: false,
    visit_comments: '',
    visitor_info: {
      visitor_id: null,
      title_prefix: '',
      first_name: '',
      last_name: '',
      company_id: '',
      employee_id: '',
      company_name: '',
      email_address: '',
      phone_number: '',
      type: '',
      created_by: '',
      updated_by: '',
      created_time: '',
      modified_time: '',
      visitor_details: {},
    },

    created_time: '',
    modified_time: '',
    custom_field_details: {
      visit_details: {},
      assigned_details: {},
      en_route_details: {},
      check_in_details: {},
      check_out_details: {},
    },
    attachments: [],
    visitor_type_id: '',
  }
  const [finalAcceptedStatus, setFinalAcceptedStatus] = React.useState([])
  const [visit, setVisit] = React.useState(initalState)
  React.useEffect(() => {
    const targetValues = env?.walkin
      ? ['VisitorField', 'VisitField', 'Check-in']
      : ['VisitorField', 'VisitField', 'Assign', 'Check-in']

    const filteredArray =
      visitorTypeDetails?.custom_field_details?.visit_statuses?.filter((item) =>
        targetValues.some(
          (target) => item.toLowerCase() === target.toLowerCase(),
        ),
      )
    setFinalAcceptedStatus(filteredArray)
  }, [env?.walkin, visitorTypeDetails])
  const handleChangeVisit = (key, obj, value) => {
    if (key === 'visit_details') {
      if (obj?.core_field === true) {
        if ([obj?.attribute_name] in visit.visitor_info) {
          setVisit((prevState) => ({
            ...prevState,
            visitor_info: {
              ...prevState.visitor_info,
              [obj.attribute_name]: value,
            },
          }))
        } else {
          setVisit((prevState) => ({
            ...prevState,
            [obj?.attribute_name]: value,
          }))
        }
      } else {
        setVisit((prevState) => ({
          ...prevState,
          custom_field_details: {
            ...prevState?.custom_field_details,
            visit_details: {
              ...prevState?.custom_field_details?.visit_details,
              [obj?.attribute_name]: value,
            },
          },
        }))
      }
    }
  }
  const handleChangeVisitor = (key, obj, value) => {
    if (key === 'visitor_details') {
      if (obj?.core_field === true) {
        if ([obj?.attribute_name] in initalState.visitor_info) {
          setVisit((prevState) => ({
            ...prevState,
            visitor_info: {
              ...prevState?.visitor_info,
              [obj.attribute_name]: value,
            },
          }))
        } else {
          setVisit((prevState) => ({
            ...prevState,
            [obj?.attribute_name]: value,
          }))
        }
      } else {
        setVisit((prevState) => ({
          ...prevState,
          visitor_info: {
            ...prevState.visitor_info,
            visitor_details: {
              ...prevState.visitor_info.visitor_details,
              [obj?.attribute_name]: value,
            },
          },
        }))
      }
    }
  }

  const handleChangeAssign = (key, obj, value) => {
    if (obj?.core_field === true) {
      if ([obj?.attribute_name] in visit.visitor_info) {
        setVisit((prevState) => ({
          ...prevState,
          visitor_info: {
            ...prevState?.visitor_info,
            [obj.attribute_name]: value,
          },
        }))
      } else {
        setVisit((prevState) => ({
          ...prevState,
          [obj?.attribute_name]: value,
        }))
      }
    } else {
      setVisit((prevState) => ({
        ...prevState,
        custom_field_details: {
          ...prevState?.custom_field_details,
          assigned_details: {
            ...prevState?.custom_field_details?.assigned_details,
            [obj.attribute_name]: value,
          },
        },
      }))
    }
  }

  const handleChangeEnRoute = (key, obj, value) => {
    if (obj?.core_field === true) {
      if ([obj?.attribute_name] in visit.visitor_info) {
        setVisit((prevState) => ({
          ...prevState,
          visitor_info: {
            ...prevState?.visitor_info,
            [obj.attribute_name]: value,
          },
        }))
      } else {
        setVisit((prevState) => ({
          ...prevState,
          [obj?.attribute_name]: value,
        }))
      }
    } else {
      setVisit((prevState) => ({
        ...prevState,
        custom_field_details: {
          ...prevState?.custom_field_details,
          en_route_details: {
            ...prevState?.custom_field_details?.en_route_details,
            [obj.attribute_name]: value,
          },
        },
      }))
    }
  }

  const handleChangeCheckInn = (key, obj, value) => {
    if (obj?.core_field === true) {
      if ([obj?.attribute_name] in visit.visitor_info) {
        setVisit((prevState) => ({
          ...prevState,
          visitor_info: {
            ...prevState?.visitor_info,
            [obj.attribute_name]: value,
          },
        }))
      } else {
        setVisit((prevState) => ({
          ...prevState,
          [obj?.attribute_name]: value,
        }))
      }
    } else {
      setVisit((prevState) => ({
        ...prevState,
        custom_field_details: {
          ...prevState.custom_field_details,
          check_in_details: {
            ...prevState?.custom_field_details?.check_in_details,
            [obj.attribute_name]: value,
          },
        },
      }))
    }
  }

  const validationForVisit = () => {
    let message = null
    Object.keys(customMetaData?.custom_field_details.visit_details).map(
      // eslint-disable-next-line
            (entry) => {
        const attributeName =
          customMetaData?.custom_field_details.visit_details[entry]
            .attribute_name

        if (
          customMetaData?.custom_field_details?.visit_details[entry].visible ===
            true &&
          customMetaData?.custom_field_details?.visit_details[entry]
            .required === true
        ) {
          if (
            customMetaData?.custom_field_details?.visit_details[entry]
              .core_field === true
          ) {
            if (
              visit[
                customMetaData?.custom_field_details.visit_details[entry]
                  .attribute_name
              ] === ''
            ) {
              message =
                message +
                customMetaData?.custom_field_details?.visit_details[entry]
                  .label +
                ' cannot be blank!   '
            }
            if (
              visit?.visitor_info[
                customMetaData?.custom_field_details.visit_details[entry]
                  .attribute_name
              ] === ''
            ) {
              message =
                message +
                customMetaData?.custom_field_details?.visit_details[entry]
                  .label +
                ' cannot be blank!   '
            }
          }
          if (
            visit?.custom_field_details?.visit_details[attributeName] === ''
          ) {
            message =
              message +
              customMetaData?.custom_field_details?.visit_details[entry].label +
              ' cannot be blank!   '
          }
        }
      },
    )
    return message
  }

  const validationForVisitor = () => {
    let message = null
    Object.keys(customMetaData?.custom_field_details.visitor_details).map(
      // eslint-disable-next-line
            (entry) => {
        const attributeName =
          customMetaData?.custom_field_details.visitor_details[entry]
            .attribute_name

        if (
          customMetaData?.custom_field_details?.visitor_details[entry]
            .visible === true &&
          customMetaData?.custom_field_details?.visitor_details[entry]
            .required === true
        ) {
          if (
            customMetaData?.custom_field_details?.visitor_details[entry]
              .core_field === true
          ) {
            if (
              visit[
                customMetaData?.custom_field_details.visitor_details[entry]
                  .attribute_name
              ] === ''
            ) {
              message =
                message +
                customMetaData?.custom_field_details?.visitor_details[entry]
                  .label +
                ' cannot be blank!   '
            }
            if (
              visit?.visitor_info[
                customMetaData?.custom_field_details.visitor_details[entry]
                  .attribute_name
              ] === ''
            ) {
              message =
                message +
                customMetaData?.custom_field_details?.visitor_details[entry]
                  .label +
                ' cannot be blank!   '
            }
          }
          if (visit?.visitor_info.visitor_details[attributeName] === '') {
            message =
              message +
              customMetaData?.custom_field_details?.visitor_details[entry]
                .label +
              ' cannot be blank!   '
          }
        }
      },
    )
    return message
  }

  const validationForAssign = () => {
    let message = null
    Object.keys(customMetaData?.custom_field_details.assigned_details).map(
      // eslint-disable-next-line
            (entry) => {
        const attributeName =
          customMetaData?.custom_field_details.assigned_details[entry]
            .attribute_name

        if (
          customMetaData?.custom_field_details?.assigned_details[entry]
            .visible === true &&
          customMetaData?.custom_field_details?.assigned_details[entry]
            .required === true
        ) {
          if (
            customMetaData?.custom_field_details?.assigned_details[entry]
              .core_field === true
          ) {
            if (
              visit[
                customMetaData?.custom_field_details.assigned_details[entry]
                  .attribute_name
              ] === ''
            ) {
              message =
                message +
                customMetaData?.custom_field_details?.assigned_details[entry]
                  .label +
                ' cannot be blank!   '
            }
            if (
              visit?.visitor_info[
                customMetaData?.custom_field_details.assigned_details[entry]
                  .attribute_name
              ] === ''
            ) {
              message =
                message +
                customMetaData?.custom_field_details?.assigned_details[entry]
                  .label +
                ' cannot be blank!   '
            }
          }
          if (
            visit?.custom_field_details?.assigned_details[attributeName] === ''
          ) {
            message =
              message +
              customMetaData?.custom_field_details?.assigned_details[entry]
                .label +
              ' cannot be blank!   '
          }
        }
      },
    )
    return message
  }

  const validationForEnroute = () => {
    let message = null
    Object.keys(customMetaData?.custom_field_details.en_route_details).map(
      // eslint-disable-next-line
            (entry) => {
        const attributeName =
          customMetaData?.custom_field_details.en_route_details[entry]
            .attribute_name

        if (
          customMetaData?.custom_field_details?.en_route_details[entry]
            .visible === true &&
          customMetaData?.custom_field_details?.en_route_details[entry]
            .required === true
        ) {
          if (
            customMetaData?.custom_field_details?.en_route_details[entry]
              .core_field === true
          ) {
            if (
              visit[
                customMetaData?.custom_field_details.en_route_details[entry]
                  .attribute_name
              ] === ''
            ) {
              message =
                message +
                customMetaData?.custom_field_details?.en_route_details[entry]
                  .label +
                ' cannot be blank!   '
            }
            if (
              visit?.visitor_info[
                customMetaData?.custom_field_details.en_route_details[entry]
                  .attribute_name
              ] === ''
            ) {
              message =
                message +
                customMetaData?.custom_field_details?.en_route_details[entry]
                  .label +
                ' cannot be blank!   '
            }
          }
          if (
            visit?.custom_field_details?.en_route_details[attributeName] === ''
          ) {
            message =
              message +
              customMetaData?.custom_field_details?.en_route_details[entry]
                .label +
              ' cannot be blank!   '
          }
        }
      },
    )
    return message
  }

  const validationForCheckIn = () => {
    let message = null
    Object.keys(customMetaData?.custom_field_details.check_in_details).map(
      // eslint-disable-next-line
            (entry) => {
        const attributeName =
          customMetaData?.custom_field_details.check_in_details[entry]
            .attribute_name

        if (
          customMetaData?.custom_field_details?.check_in_details[entry]
            .visible === true &&
          customMetaData?.custom_field_details?.check_in_details[entry]
            .required === true
        ) {
          if (
            customMetaData?.custom_field_details?.check_in_details[entry]
              .core_field === true
          ) {
            if (
              visit[
                customMetaData?.custom_field_details.check_in_details[entry]
                  .attribute_name
              ] === ''
            ) {
              message =
                message +
                customMetaData?.custom_field_details?.check_in_details[entry]
                  .label +
                ' cannot be blank!   '
            }
            if (
              visit?.visitor_info[
                customMetaData?.custom_field_details.check_in_details[entry]
                  .attribute_name
              ] === ''
            ) {
              message =
                message +
                customMetaData?.custom_field_details?.check_in_details[entry]
                  .label +
                ' cannot be blank!   '
            }
          }
          if (
            visit?.custom_field_details?.check_in_details[attributeName] === ''
          ) {
            message =
              message +
              customMetaData?.custom_field_details?.check_in_details[entry]
                .label +
              ' cannot be blank!   '
          }
        }
      },
    )

    return message
  }

  const validationForCheckOut = () => {
    //Check validation for visitor
    let message = null
    Object.keys(customMetaData?.custom_field_details?.check_out_details).map(
      // eslint-disable-next-line
            (entry) => {
        const attributeName =
          customMetaData?.custom_field_details?.check_out_details[entry]
            .attribute_name

        if (
          customMetaData?.custom_field_details?.check_out_details[entry]
            .visible === true &&
          customMetaData?.custom_field_details?.check_out_details[entry]
            .required === true
        ) {
          if (
            customMetaData?.custom_field_details?.check_out_details[entry]
              .core_field === true
          ) {
            if (visit[attributeName] === '') {
              message =
                message +
                customMetaData?.custom_field_details?.check_out_details[entry]
                  .label +
                ' cannot be blank!  '
            }
            if (
              visit?.visitor_info[
                customMetaData?.custom_field_details.check_in_details[entry]
                  .attribute_name
              ] === ''
            ) {
              message =
                message +
                customMetaData?.custom_field_details?.check_in_details[entry]
                  .label +
                ' cannot be blank!   '
            }
          }
          if (
            visit.custom_field_details.check_out_details[attributeName] === ''
          ) {
            message =
              message +
              customMetaData?.custom_field_details.check_out_details[entry]
                .label +
              ' cannot be blank!   '
          }
        }
      },
    )

    return message
  }
  function MapValidatorFunction(statusName) {
    let ErrorMessage = null
    switch (statusName) {
      case 'VisitField':
        ErrorMessage = validationForVisit()
        return ErrorMessage
      case 'VisitorField':
        ErrorMessage = validationForVisitor()
        return ErrorMessage
      case 'Assigned':
        ErrorMessage = validationForAssign()
        return ErrorMessage
      case 'En-Route':
        ErrorMessage = validationForEnroute()
        return ErrorMessage
      case 'Check-in':
        ErrorMessage = validationForCheckIn()
        return ErrorMessage
      case 'Check-out':
        ErrorMessage = validationForCheckOut()
        return ErrorMessage
      default:
        return 'Error'
    }
  }

  function statusNameHandlers(status) {
    switch (status) {
      case 'VisitField':
        return 'VisitField'
      case 'VisitorField':
        return 'VisitorField'
      case 'Assign':
        return 'Assigned'
      case 'En-Route':
        return 'En Route'
      case 'Check-in':
        return 'Check-in'
      case 'Check-out':
        return 'Check-out'
      default:
        return 'Check-in'
    }
  }

  const handleSuccesToast = (errortext) => {
    makeToast({
      type: 'success',
      heading: 'Success',
      message: errortext,
    })
  }
  const handleFailureToast = (errortext) => {
    makeToast({
      type: 'error',
      heading: 'Error',
      message: errortext,
    })
  }

  const handleSingleViewSubmit = (event) => {
    const errorValidationFunctions = []

    finalAcceptedStatus.forEach((status, index) => {
      const statusHandler = statusNameHandlers(status)
      window[`ErrorValidationFunction${index + 1}`] =
        MapValidatorFunction(statusHandler)

      const currentErrorValidationFunction =
        window[`ErrorValidationFunction${index + 1}`]

      if (
        currentErrorValidationFunction?.length > 0 &&
        currentErrorValidationFunction !== undefined &&
        currentErrorValidationFunction !== null
      ) {
        handleFailureToast(currentErrorValidationFunction)
      }

      errorValidationFunctions.push(currentErrorValidationFunction)
    })

    if (errorValidationFunctions.every((func) => func === null)) {
      // All error validation functions are null
      // setCircleProgress(true);
      handleSubmit(event)
    }
  }
  const handleSubmit = (event) => {
    const statusFiltered = env?.walkin
      ? statusNameHandlers(finalAcceptedStatus[2])
      : statusNameHandlers(finalAcceptedStatus[finalAcceptedStatus.length - 1])

    visit.system_name = systemName
    visit.location_id = locationId
    visit.user_id = `${systemName}-kiosk`
    visit.attachments = signedDocument
    visit.visit_status = statusFiltered
    visit.estimated_time_arrival = visit.estimated_time_arrival
      ? moment
          .utc(visit.estimated_time_arrival, 'YYYYMMDD HH:mm:ss')
          .toISOString()
      : moment(moment().utcOffset(0).toISOString())
    visit.visitor_type_id = visitorTypeId
    CreateVisitService(visit, visitUUID).then((res) => {
      setResponse({
        routeFlag: 'created',
        flag: '',
        data: res?.data?.created_time,
      })
      handleSuccesToast('')
    })
  }

  const getListStepView = React.useMemo(() => {
    return (stepName) => {
      switch (stepName) {
        case 'VisitField':
          return (
            <>
              {customMetaData?.custom_field_details?.visit_details?.length >
              0 ? (
                <>
                  <RenderFields
                    field={'visit_details'}
                    customData={
                      customMetaData?.custom_field_details?.visit_details
                    }
                    domainLookUp={domainLookUp}
                    handleChangeOption={handleChangeVisit}
                    visit={visit}
                    visitCustomText={visit?.custom_field_details?.visit_details}
                    setAutocompleteValue={setAutocompleteValue}
                    autocompleteValue={autocompleteValue}
                    setSignedDocument={setSignedDocument}
                    signedDocument={signedDocument}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )
        case 'VisitorField':
          return (
            <>
              {customMetaData?.custom_field_details?.visitor_details?.length >
              0 ? (
                <>
                  <RenderFields
                    field={'visitor_details'}
                    customData={
                      customMetaData?.custom_field_details?.visitor_details
                    }
                    domainLookUp={domainLookUp}
                    handleChangeOption={handleChangeVisitor}
                    visit={visit}
                    visitCustomText={visit?.visitor_info}
                    setAutocompleteValue={setAutocompleteValue}
                    autocompleteValue={autocompleteValue}
                    setSignedDocument={setSignedDocument}
                    signedDocument={signedDocument}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )
        case 'Assign':
          return (
            <>
              {customMetaData?.custom_field_details?.assigned_details?.length >
              0 ? (
                <>
                  <RenderFields
                    field={'assigned_details'}
                    customData={
                      customMetaData?.custom_field_details?.assigned_details
                    }
                    domainLookUp={domainLookUp}
                    handleChangeOption={handleChangeAssign}
                    visit={visit}
                    visitCustomText={
                      visit?.custom_field_details?.assigned_details
                    }
                    setAutocompleteValue={setAutocompleteValue}
                    autocompleteValue={autocompleteValue}
                    setSignedDocument={setSignedDocument}
                    signedDocument={signedDocument}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )
        case 'En-Route':
          return (
            <>
              {customMetaData?.custom_field_details?.en_route_details?.length >
              0 ? (
                <>
                  <RenderFields
                    field={'en_route_details'}
                    customData={
                      customMetaData?.custom_field_details?.en_route_details
                    }
                    domainLookUp={domainLookUp}
                    handleChangeOption={handleChangeEnRoute}
                    visit={visit}
                    visitCustomText={
                      visit?.custom_field_details?.en_route_details
                    }
                    setAutocompleteValue={setAutocompleteValue}
                    autocompleteValue={autocompleteValue}
                    setSignedDocument={setSignedDocument}
                    signedDocument={signedDocument}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )
        case 'Check-in':
          return (
            <>
              {customMetaData?.custom_field_details?.check_in_details.length >
              0 ? (
                <>
                  <RenderFields
                    field={'check_in_details'}
                    customData={
                      customMetaData?.custom_field_details?.check_in_details
                    }
                    domainLookUp={domainLookUp}
                    handleChangeOption={handleChangeCheckInn}
                    visit={visit}
                    visitCustomText={
                      visit?.custom_field_details?.check_in_details
                    }
                    setAutocompleteValue={setAutocompleteValue}
                    autocompleteValue={autocompleteValue}
                    setSignedDocument={setSignedDocument}
                    signedDocument={signedDocument}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )
        case 'Check-Out':
          return <></>
        default:
          return 'Unknown step'
      }
    }
  }, [
    customMetaData?.custom_field_details?.visit_details,
    customMetaData?.custom_field_details?.visitor_details,
    customMetaData?.custom_field_details?.assigned_details,
    customMetaData?.custom_field_details?.en_route_details,
    customMetaData?.custom_field_details?.check_in_details,
    domainLookUp,
    handleChangeVisit,
    visit,
    autocompleteValue,
    signedDocument,
    handleChangeVisitor,
    handleChangeAssign,
    handleChangeEnRoute,
    handleChangeCheckInn,
  ])
  return (
    <>
      <Layout.Body includeRail>
        <Grid.Container
          direction="row"
          justify="flex-start"
          style={{ paddingBottom: '20px' }}
          xs={12}
        >
          <Grid.Item xs={12}>
            <Input.Info className="hc-fs-xl">Target Check-in Form</Input.Info>
            <Input.Info className="hc-fs-md">
              Submit your details now for instant check-in at the Target
              facility{' '}
            </Input.Info>
            <Divider width="2" direction="horizontal" wide={5} />
            <Grid.Container direction="row" justify="space-between">
              <Grid.Item>
                <Input.Info className="hc-bg-grey04 hc-pa-lg">
                  <Grid.Container
                    direction="row"
                    justify="center"
                    align="flex-start"
                  >
                    <Grid.Item className="hc-pt-dense">
                      Visitor Type: <strong>{visitorTypeDetails?.type}</strong>
                    </Grid.Item>
                  </Grid.Container>
                </Input.Info>
              </Grid.Item>
              <Grid.Item>
                <Input.Info className="hc-bg-grey04 hc-pa-lg">
                  <Grid.Container
                    direction="row"
                    justify="center"
                    align="flex-start"
                  >
                    <Grid.Item className="hc-pt-dense">
                      Location Id: <strong>{locationId}</strong>
                    </Grid.Item>
                  </Grid.Container>
                </Input.Info>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>

        <Grid.Container
          justify="center"
          direction="column"
          spacing="dense"
          //align="center"
          xs={12}
        >
          <Grid.Item xs={12}>
            <Input
              xs={12}
              noValidate
              onSubmit={() => {
                console.log('Input submitted')
              }}
            >
              {finalAcceptedStatus?.map((mapingObjects) => {
                return <>{getListStepView(mapingObjects)}</>
              })}
            </Input>
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
      <Layout.Footer includeRail>
        <Grid.Container justify="flex-end">
          <Grid.Item>
            {customMetaData ? (
              <>
                <Button
                  type="primary"
                  size="expanded"
                  className="hc-pa-expanded"
                  onClick={() => handleSingleViewSubmit()}
                >
                  Submit Details <EnterpriseIcon icon={ChevronRightIcon} />
                </Button>
              </>
            ) : (
              <></>
            )}
          </Grid.Item>
        </Grid.Container>
      </Layout.Footer>
    </>
  )
}
