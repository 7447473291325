/* eslint-disable no-unused-vars */
import React from 'react'
import { Spinner } from '@enterprise-ui/canvas-ui-react'
import { LoadingContext } from '../../contexts/LoadingContext'

export const LoadingComponent = () => {
  const { Loading } = React.useContext(LoadingContext)

  return (
    <>
      {Loading === true ? (
        <div className="Loadcontainer">
          <div className="progressContainer">
            <Spinner size="expanded" />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
