import { useState, useEffect } from 'react'

const Base64AttachImage = ({ base64String }) => {
  const [imageSrc, setImageSrc] = useState('')
  const imageContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    height: 'auto',
  }

  const responsiveImage = {
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'contain',
    borderRadius: '8px',
  }
  useEffect(() => {
    // Convert Base64 string to Blob
    const byteCharacters = atob(base64String)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: 'image/jpeg' })

    // Create a URL for the Blob
    const imageUrl = URL.createObjectURL(blob)
    setImageSrc(imageUrl)

    // Cleanup the URL object when the component is unmounted
    return () => URL.revokeObjectURL(imageUrl)
  }, [base64String])

  return (
    <div style={imageContainer}>
      {imageSrc ? (
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        <img src={imageSrc} alt="Base64 Image" style={responsiveImage} />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  )
}

export default Base64AttachImage
