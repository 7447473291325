import * as React from 'react'
const ResponseContext = React.createContext()

const ResponseContextProvider = ({ children }) => {
  const [response, setResponse] = React.useState({
    routeFlag: '',
    flagStatus: '',
    data: '',
  })
  return (
    <>
      <ResponseContext.Provider
        value={{
          setResponse,
          response,
        }}
      >
        {children}
      </ResponseContext.Provider>
    </>
  )
}
export { ResponseContext, ResponseContextProvider }
