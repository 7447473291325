import {
  Grid,
  Card,
  Layout,
  ExpandableSection,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import Base64Image from './ConvertBase64toImage'

const BotResponseBubble = ({
  data,
  generalComments,
  attachements,
  caseAttachmentsList,
}) => {
  const cardStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%', // Ensure the card takes up the available height
    width: '100%', // Ensure the card takes up the available width
    padding: '20px', // Add padding around the iframe
    border: '2px solid #000', // Set the border for the card
    borderRadius: '10px', // Optional: add rounded corners to the card
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: add a subtle shadow for a better visual effect
    boxSizing: 'border-box', // Include padding and border in the element's total width and height
  }

  return (
    <>
      <Layout.Body includeRail>
        <Grid.Container
          justify="space-around"
          direction="column"
          spacing="dense"
        >
          {data.visitArr.map((item, index) => (
            <Grid.Item>
              <Card>
                <div className="hc-pa-normal hc-bg-grey06">
                  <Grid.Container
                    direction="row"
                    justify="flex-start"
                    align="center"
                  >
                    <Grid.Item>
                      <p className="hc-fs-lg"> {`${item.label} : `}</p>
                    </Grid.Item>
                    <Grid.Item>
                      <p className="hc-fs-lg">{`${item.value}`}</p>
                    </Grid.Item>
                  </Grid.Container>
                </div>
              </Card>
            </Grid.Item>
          ))}

          {generalComments?.length > 0 && (
            <ExpandableSection>
              <>
                <Heading size={4}>Comments (Click to expand) </Heading>
              </>

              <ExpandableSection.Content>
                {generalComments.map((comment, i) => (
                  <Card>
                    <div className="hc-pa-normal hc-bg-grey06">
                      <Grid.Item>
                        <p className="hc-fs-lg">{comment?.comment}</p>
                      </Grid.Item>
                    </div>
                  </Card>
                ))}
              </ExpandableSection.Content>
            </ExpandableSection>
          )}

          {caseAttachmentsList?.length > 0 ? (
            <ExpandableSection>
              <>
                <Heading size={4}>Attachments (Click to expand) </Heading>
              </>

              <ExpandableSection.Content>
                {caseAttachmentsList?.map((attachments, i) => (
                  <Card style={cardStyles}>
                    <Base64Image base64String={attachments?.base64_data} />
                  </Card>
                ))}
              </ExpandableSection.Content>
            </ExpandableSection>
          ) : (
            <></>
          )}
        </Grid.Container>
      </Layout.Body>
    </>
  )
}

export default BotResponseBubble
