/* eslint-disable jsx-a11y/alt-text */
// import React from 'react'
import { Grid, Layout } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { HelpIcon } from '@enterprise-ui/icons'
import Logo from '../../assets/images/image1.png'
// import { useNavigate } from 'react-router-dom'

export const Header = () => {
  // const navigate = useNavigate()
  return (
    <>
      <Layout.Header style={{ paddingTop: '20px' }} includeRail fixed>
        <Grid.Container direction="row" justify="space-between" xs={12}>
          <Grid.Item>
            <div className="imageLogo">
              <img src={Logo} className="img" alt="Logo" />
            </div>
          </Grid.Item>
          <Grid.Item>
            <Grid.Container justify="flex-end" spacing="none">
              <Grid.Item>
                <EnterpriseIcon
                  icon={HelpIcon}
                  size="xl"
                  className="hc-clr-success"
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </Layout.Header>
    </>
  )
}
