import { Heading, Card, Layout } from '@enterprise-ui/canvas-ui-react'
import { useEnv } from '@praxis/component-runtime-env'

export const Home = () => {
  const env = useEnv()
  return (
    <>
      <Layout.Body includeRail>
        <Card>
          <div className="hc-pa-normal hc-ta-center">
            <Heading heading={5}>Welcome to {env.HomeText}</Heading>
            <p className="hc-pa-normal hc-fs-md">
              You navigated to an unused page in {env.HomeText}. Please use your
              company link or the direct visit link to get started.
            </p>
          </div>
        </Card>
      </Layout.Body>
    </>
  )
}
