import React from 'react'
import { Button, Grid, Input, Layout } from '@enterprise-ui/canvas-ui-react'
import PlaywoodIcon from '../../assets/Icons/playwood.png'
import { ResponseContext } from '../../contexts/ResponseContext'
import { useNavigate } from 'react-router-dom'
// import { ScreenAccessContext } from '../../contexts/ScreenAccessContext'
// import moment from 'moment-timezone'
// import { useEnv } from '@praxis/component-runtime-env'
import { ScreenAccessContext } from '../../contexts/ScreenAccessContext'
// import { VisitUUIDContext } from '../../contexts/VisitUUIDContext'

export const SuccessScreenExtVisit = () => {
  const { response } = React.useContext(ResponseContext)
  const { setScreenAccess } = React.useContext(ScreenAccessContext)
  const { setResponse } = React.useContext(ResponseContext)
  // const { locationId } = React.useContext(VisitUUIDContext)
  //   const env = useEnv()
  const navigate = useNavigate()

  // function convertUtcTimeToStoreTime(time) {
  //   const utcTime = moment.utc(time, 'YYYYMMDD HH:mm:ss')
  //   const storeTime =
  //     utcTime &&
  //     utcTime
  //       .clone()
  //       .tz(optedLocationDetail === undefined ? 'America/Chicago' : locationId)
  //       .format('YYYY-MM-DD h:mm:ss A')
  //   return storeTime
  // }

  // React.useEffect(() => {
  //   setTimeout(() => navigate('/'), 1500)
  // }, [navigate])

  return (
    <>
      <Layout.Body includeRail>
        <Grid.Container direction="column" justify="center" align="center">
          <Grid.Item xs={12} sm={6} md={4}>
            <img src={PlaywoodIcon} alt="playwoodicon" />
          </Grid.Item>
          <Grid.Item xs={12} sm={8} md={8} align="center">
            <Input.Info className="hc-fs-lg hc-clr-success">
              {' '}
              You have successfully{' '}
              {response?.flag === 'checkincreated' ? (
                <>checked-in</>
              ) : (
                <>checked-out</>
              )}{' '}
              of Target facility at{' '}
            </Input.Info>
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
      <Layout.Footer includeRail>
        <Grid.Container
          direction="row"
          justify="flex-end"
          className="hc-pa-expanded"
        >
          <Grid.Item>
            <Button
              type="primary"
              className="hc-pa-expanded"
              size="expanded"
              onClick={() => {
                setScreenAccess({
                  createvisit: false,
                  checkin: false,
                  checkout: false,
                })
                setResponse({
                  routeFlag: 'netural',
                })
                navigate('/')
              }}
            >
              Okay
            </Button>
          </Grid.Item>
        </Grid.Container>
      </Layout.Footer>
    </>
  )
}
